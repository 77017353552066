import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Accordion = ({ title, children }) => {
  const [toggle, setToggle] = useState(false);

  const toggleAccordion = () => {
    setToggle(!toggle);
  };

  return (
    <div className="border-b-2">
      <h4 className="font-normal text-xl">
        <button
          className={`accordion-button ${
            toggle ? 'accordion-button--toggled' : ''
          }`}
          onClick={toggleAccordion}
        >
          {title}
        </button>
      </h4>
      <div
        className={`children-margin pt-1 pb-5 ${toggle ? 'block' : 'hidden'}`}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Accordion;
