const content = {
  title: 'Newsletter Anmeldung',
  headvisualHeadline: 'Newsletter Anmeldung',
  sectionHead: {
    headline: 'Zum Newsletter anmelden',
    paragraph:
      'Bitte geben Sie folgende Daten an, um den Newsletter zu abonnieren:'
  },
  errors: {
    title: 'Bitte wählen Sie eine Anrede.',
    noFirstName: 'Der Vorname ist eine Pflichtangabe.',
    noLastName: 'Der Nachname ist eine Pflichtangabe.',
    noMail: 'Die E-Mail ist eine Pflichtangabe.',
    invalidMail: 'Ungültige E-Mail-Adresse.',
    uncheckedDataPrivacy: 'Die Datenschutzerklärung muss akzeptiert sein.'
  },
  dataPrivacy: {
    label: 'Einwilligung in Datenschutzbestimmungen*',
    paragraph:
      'Wir verarbeiten Ihre personenbezogenen Daten nur im zulässigen Rahmen der gesetzlichen Bestimmungen, insbesondere der EU-Datenschutz-Grundverordnung (DSGVO). Für weitere Informationen, lesen Sie bitte die ergänzenden Hinweise zum Datenschutz.'
  },
  requiredNotice: '* Die mit Stern markierten Felder sind Pflichtfelder.',
  submitButton: 'Abschicken',
  message: {
    success: 'Die Anmeldung wurde erfolgreich versendet.',
    fail:
      'Die Anmeldung konnte leider nicht versendet werden. Bitte versuchen Sie es später nochmal.'
  }
};

export default content;
