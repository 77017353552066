const lightBackgrounds = [
  'white',
  'light-blue',
  'light-steel-blue',
  'medium-steel-blue',
  'light-pink',
  'light-grey-blue',
  'light-salmon'
];

export default lightBackgrounds;
