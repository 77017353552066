
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MediaAccordion = ({ title, children }) => {
  const [toggle, setToggle] = useState(false);

  const toggleAccordion = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <h4 className="font-normal text-xl">
        <button
          className={`media-accordion-button bg-light-blue mt-5 ${toggle ? 'media-accordion-button--toggled' : ''}`}
          onClick={toggleAccordion}
        >
          {title}
        </button>
      </h4>
      <div
        className={`children-margin bg-light-blue py-2 ${toggle ? 'block' : 'hidden'}`}
      >
        {children}
      </div>
    </div>
  );
};

MediaAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default MediaAccordion;
