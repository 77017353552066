import React from 'react';
import ReactDOMServer from 'react-dom/server';

// 'strippedText' ähnlich wie 'text' nur ohne Steuerzeichen für das Footnote-Parsing. Genutzt vorrangig für das Title-Attribute.
const footnoteDefinitions = [
  {
    anchorId: 'footnote-gender',
    text: 'männlich/weiblich/divers',
    strippedText: 'männlich/weiblich/divers'
  },
  {
    anchorId: 'footnote-system',
    text: 'abhängig von Systemanbindung',
    strippedText: 'abhängig von Systemanbindung'
  },
  {
    anchorId: 'footnote-price',
    text:
      'Preis pro Mitarbeiter#(footnote-gender|1)# Praktikanten#(footnote-gender|1)# und Rentner#(footnote-gender|1)# kosten 25,50 ohne jeden weiteren Aufschlag; der mtl. Höchstbetrag liegt hier bei 255,00 EUR.',
    strippedText:
      'Preis pro Mitarbeiter Praktikanten und Rentner kosten 25,50 ohne jeden weiteren Aufschlag; der mtl. Höchstbetrag liegt hier bei 255,00 EUR.'
  }
];

/**
 * includes the linked footnote number in given html markup html. The placeholder #([link]|[number])# will be replaced by the footnote markup html.
 * For example: #(myAnchorLink|2)#    --->    <Footnote anchorId={'myAnchorLink'}>{2}</Footnote>
 * @param {string} markup - the html markup which needs footnote links in it
 * @returns {string} The html markup with footnote html (linked footnote number)
 */
function Footnote({ children }) {
  // Kein Footer-Code gefunden -> gib das HTML unverändert zurück
  if (children.indexOf(')#') === -1) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: children
        }}
      ></span>
    );
  }
  //der Footer-Code wird in ein ordentliches Markup verwandelt.
  const footnoteData = children.split(')#').slice(0, -1);
  const newFootnote = footnoteData.map(segment => {
    const segmentParts = segment.split('#(');
    const payload = segmentParts[1].split('|');
    const newMarkup = (
      <a
        href={`#${payload[0]}`}
        className="align-top text-xs ml-px cursor-pointer no-underline"
        title={footnoteDefinitions[parseInt(payload[1]) - 1].strippedText}
      >
        [<span className="text-xxs">{payload[1]}</span>]
      </a>
    );

    return `<span>${segmentParts[0]}${ReactDOMServer.renderToString(
      newMarkup
    )}</span>`;
  });

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: newFootnote.join()
      }}
    ></span>
  );
}

/**
 * Read Data from footnoteDefinition-object and outputs a html with all footnote-descriptions in an ordered list
 * @returns {string} html of ordered list with all footnote descriptions
 */
function FootnoteOutput() {
  return (
    <ol className="container list-decimal pl-6">
      {footnoteDefinitions.map((footnoteDefinition, index) => (
        <li key={index} id={footnoteDefinition.anchorId}>
          <Footnote>{footnoteDefinition.text}</Footnote>
        </li>
      ))}
    </ol>
  );
}
export { Footnote, FootnoteOutput };
