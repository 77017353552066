import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

const NonStretchedImage = props => {
  let normalizedProps = props;

  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    };
  }

  return <Img {...normalizedProps} />;
};

export default NonStretchedImage;
