import React from 'react';
import PropTypes from 'prop-types';
import { default as A11YDialog } from 'react-a11y-dialog';

class Dialog extends React.Component {
  classes = {
    base:
      'fixed z-50 top-0 bottom-0 left-0 right-0 flex justify-center items-center p-8 pt-10',
    overlay: 'absolute top-0 bottom-0 left-0 right-0 bg-dark-blue opacity-75',
    element:
      'relative max-h-full bg-white text-dark-blue p-8 pb-0 shadow-lg overflow-x-hidden overflow-y-scroll',
    title: 'text-xl md:text-3xl',
    document: 'children-margin pb-8',
    closeButton:
      'fixed top-0 right-0 inline-flex w-12 h-12 justify-center bg-gray-blue text-3rem text-white leading-1.2 rounded-full shadow-lg mt-4 mr-4'
  };

  componentWillUnmount() {
    this.dialog.destroy();
  }

  setupDialog = ref => {
    const body = document.body;

    if (ref) {
      /**
       * Set ref
       */
      this.dialog = ref;

      /**
       * Disable scrolling when dialog is open
       */
      this.dialog.on('show', () => {
        body.classList.add('overflow-hidden');
      });

      /**
       * Enable scrolling when dialog is closed
       */
      this.dialog.on('hide', () => {
        body.classList.remove('overflow-hidden');
      });
    }
  };

  showDialog = () => {
    this.dialog.show();
  };

  render() {
    const { buttonText, buttonVariant, title, children } = this.props;

    return (
      <>
        <button
          type="button"
          className={`button button--${buttonVariant}`}
          onClick={this.showDialog}
        >
          {buttonText}
        </button>
        <A11YDialog
          id="a11y-dialog"
          appRoot="#___gatsby"
          dialogRoot="#dialog-root"
          dialogRef={ref => this.setupDialog(ref)}
          useDialog={false}
          closeButtonLabel="Dialogfenster schließen"
          title={title}
          titleId={title}
          classNames={this.classes}
        >
          {children}
        </A11YDialog>
      </>
    );
  }
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired
};

export default Dialog;
