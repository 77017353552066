import React from 'react';
import PropTypes from 'prop-types';

const Grid = ({ centerItems, columns, children }) => {
  const columnsCount = columns ? columns : children.length;

  let containerClasses = 'container container--grid';
  let childClasses = 'px-4 my-4 md:w-1/2';

  if (centerItems) {
    containerClasses += ' items-center';
  }

  if (columnsCount > 2) {
    childClasses += ` lg:w-1/${columnsCount}`;
  }

  return (
    <div className={containerClasses}>
      {children.map((child, index) => (
        <div key={index} className={childClasses}>
          {child}
        </div>
      ))}
    </div>
  );
};

Grid.propTypes = {
  centerItems: PropTypes.bool,
  columns: PropTypes.number,
  children: PropTypes.node.isRequired
};

export default Grid;
