import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Button = ({ href, external, variant, fullWidth, children }) => {
  let classNames = `button button--${variant}`;
  let button;

  if (fullWidth) {
    classNames += ' flex justify-center w-full';
  }

  if (href && external) {
    /**
     * External Link
     */
    button = (
      <a href={href} className={classNames}>
        {children}
      </a>
    );
  } else if (href) {
    /**
     * Gatsby Link
     */
    button = (
      <Link to={href} className={classNames}>
        {children}
      </Link>
    );
  } else {
    /**
     * Regular Button
     */
    button = (
      <button type="button" className={classNames}>
        {children}
      </button>
    );
  }

  return button;
};

Button.propTypes = {
  href: PropTypes.string,
  external: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Button;
