import React from 'react';
import PropTypes from 'prop-types';

const SectionHead = ({
  topline,
  headline,
  subheadline,
  children,
  centered,
  gap,
  shifted
}) => {
  let classNames = 'container container--simple children-margin';
  let headlineClasses = 'hyphens-auto';

  if (centered) {
    classNames += ' text-center';
  }

  if (shifted) {
    classNames +=
      ' relative z-10 max-w-screen-xl bg-inherit xl:pt-16 xl:-mt-56';
  }

  if (gap) {
    classNames += ' mb-16';
  }

  if (topline) {
    headlineClasses += ' -mt-3';
  }

  return (
    <div className={classNames}>
      {topline && <h3 className="text-xl font-normal">{topline}</h3>}
      <h2 className={headlineClasses}>{headline}</h2>
      {subheadline && <h4>{subheadline}</h4>}
      {children}
    </div>
  );
};

SectionHead.propTypes = {
  topline: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string,
  children: PropTypes.node,
  centered: PropTypes.bool,
  gap: PropTypes.bool,
  shifted: PropTypes.bool
};

export default SectionHead;
