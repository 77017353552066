import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Grid } from 'src/components';

import content from 'src/content/aktuelles/newsletter';

class NewsletterForm extends React.Component {
  state = {
    isSubmitting: false
  };

  classes = {
    label: 'flex flex-col',
    input: 'form-input mt-2',
    radio: 'form-radio w-6 h-6 text-turquoise mr-2',
    checkbox: 'form-checkbox w-6 h-6 text-turquoise mr-2',
    error: 'text-base text-traffic-light-red mt-2'
  };

  formValues = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    dataPrivacy: false
  };

  validateForm = values => {
    let errors = {};

    if (values.title === '') {
      errors.title = content.errors.title;
    }

    if (values.firstName === '') {
      errors.firstName = content.errors.noFirstName;
    }

    if (values.lastName === '') {
      errors.lastName = content.errors.noLastName;
    }

    if (values.email === '') {
      errors.email = content.errors.noMail;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = content.errors.invalidMail;
    }

    if (!values.dataPrivacy) {
      errors.dataPrivacy = content.errors.uncheckedDataPrivacy;
    }

    return errors;
  };

  showErrorMessage = actions => {
    actions.setStatus({
      type: 'fail',
      message: content.message.fail
    });
  };

  sendForm = async (values, actions) => {
    this.setState({
      isSubmitting: true
    });

    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-api-key':  process.env.GATSBY_BACKEND_API_KEY
    });

    const body = JSON.stringify({
      gender: values.title === 'Herr' ? 'm' : 'f',
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email
    });

    const options = {
      method: 'POST',
      headers,
      body
    };

    const url = process.env.GATSBY_BACKEND_URL + "/newsletter";

    fetch(url, options)
      .then((response) => {
        console.log(response)
        if (response.ok) {
          actions.setStatus({
            type: 'success',
            message: content.message.success
          });
        } else {
          this.showErrorMessage(actions);
        }
      })
      .catch((err) => {
        console.log(err)
        this.showErrorMessage(actions);
      });
  };

  render() {
    const { isSubmitting } = this.state;
    const { classes } = this;

    return (
      <Formik
        initialValues={this.formValues}
        validate={values => this.validateForm(values)}
        onSubmit={(values, actions) => this.sendForm(values, actions)}
      >
        {({ status }) => (
          <Form className="children-margin">
            <div className="flex flex-col container container--simple">
              <span className="mb-2">Anrede:*</span>
              <div className="flex mb-2">
                <label htmlFor="herr" className="flex items-center mr-4">
                  <Field
                    type="radio"
                    id="herr"
                    name="title"
                    value="Herr"
                    className={classes.radio}
                  />
                  Herr
                </label>
                <label htmlFor="frau" className="flex items-center">
                  <Field
                    type="radio"
                    id="frau"
                    name="title"
                    value="Frau"
                    className={classes.radio}
                  />
                  Frau
                </label>
              </div>
              <ErrorMessage
                name="title"
                render={message => (
                  <div className={classes.error}>{message}</div>
                )}
              />
            </div>
            <Grid>
              <div>
                <label htmlFor="first-name" className={classes.label}>
                  Vorname*
                  <Field
                    type="text"
                    id="first-name"
                    name="firstName"
                    placeholder="Vorname"
                    className={classes.input}
                  />
                </label>
                <ErrorMessage
                  name="firstName"
                  render={message => (
                    <div className={classes.error}>{message}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="last-name" className={classes.label}>
                  Nachname*
                  <Field
                    type="text"
                    id="last-name"
                    name="lastName"
                    placeholder="Nachname"
                    className={classes.input}
                  />
                </label>
                <ErrorMessage
                  name="lastName"
                  render={message => (
                    <div className={classes.error}>{message}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="email" className={classes.label}>
                  E-Mail*
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-Mail"
                    className={classes.input}
                  />
                </label>
                <ErrorMessage
                  name="email"
                  render={message => (
                    <div className={classes.error}>{message}</div>
                  )}
                />
              </div>
            </Grid>
            <div className="container container--simple children-margin">
              <div>
                <label htmlFor="data-privacy" className="flex items-center">
                  <Field
                    type="checkbox"
                    id="data-privacy"
                    name="dataPrivacy"
                    className={classes.checkbox}
                  />
                  {content.dataPrivacy.label}
                </label>
                <ErrorMessage
                  name="dataPrivacy"
                  render={message => (
                    <div className={classes.error}>{message}</div>
                  )}
                />
              </div>
              <p>{content.dataPrivacy.paragraph}</p>
              <p className="text-sm">{content.requiredNotice}</p>
              <div className="text-center">
                {status === undefined && (
                  <input
                    type="submit"
                    value={content.submitButton}
                    disabled={isSubmitting}
                    className="button button--wide button--peach"
                  />
                )}
              </div>
              {status && status.message && (
                <p
                  className={`font-bold text-xl text-center mx-auto ${
                    status.type === 'success'
                      ? 'text-traffic-light-green'
                      : 'text-traffic-light-red'
                  }`}
                >
                  {status.message}
                </p>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default NewsletterForm;
