import React from 'react';
import PropTypes from 'prop-types';

import CheckmarkIcon from 'src/assets/icons/checkmark.svg';
import ArrowIcon from 'src/assets/icons/arrow.svg';
import { Footnote } from 'src/utils/footnote';

const ListItem = ({ item, icon, widthClass }) => {
  const iconClasses = 'w-4 h-4 flex-none fill-current mt-1 mr-2';

  let givenIcon = item.icon || icon;
  let itemIcon;

  if (givenIcon) {
    itemIcon = (
      <svg className={iconClasses} {...givenIcon.defaultProps}>
        {givenIcon().props.children}
      </svg>
    );
  } else {
    itemIcon = <CheckmarkIcon className={iconClasses} />;
  }

  if (item.href && item.icon) {
    /**
     * List item with link + href
     */
    return (
      <li
        className={`border-b-2 last:border-b-0 hyphens-auto py-2 pr-2${widthClass}`}
      >
        <a
          href={item.href}
          className="flex flex-row hyphens-auto link-underline-hover"
          target="_blank"
          rel="noreferrer"
        >
          {itemIcon}
          {item.text}
        </a>
      </li>
    );
  }

  if (item.href) {
    /**
     * List item with link
     */
    return (
      <li className={`hyphens-auto py-2 pr-2 ${widthClass}`}>
        <a
          href={item.href}
          className="flex flex-row hyphens-auto link-underline-hover"
        >
          <ArrowIcon className={iconClasses} />
          {item.text}
        </a>
      </li>
    );
  } else {
    /**
     * List item with icon
     */
    return (
      <li className={`flex flex-row hyphens-auto py-2 pr-2 ${widthClass}`}>
        {itemIcon}
        <Footnote>{typeof item === 'object' ? item.text : item}</Footnote>
      </li>
    );
  }
};

const List = ({ items, icon, columns }) => {
  const widthClass = columns ? `lg:w-1/${columns}` : 'w-full';

  return (
    <ul
      className={`mb-2  ${
        columns === 1 ? '' : 'lg:flex lg:flex-wrap lg:items-start'
      }`}
    >
      {items.map((item, index) => (
        <ListItem key={index} item={item} icon={icon} widthClass={widthClass} />
      ))}
    </ul>
  );
};

List.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.func,
        href: PropTypes.string,
        text: PropTypes.string
      })
    ),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  columns: PropTypes.number
};

export default List;
