import React from 'react';
import PropTypes from 'prop-types';

import lightBackgrounds from 'src/data/light-backgrounds';

const ContentBox = ({
  topline,
  headline,
  background,
  wider,
  single,
  align,
  shifted,
  children
}) => {
  let classNames = 'children-margin p-10 xl:p-16';

  if (background) {
    classNames += ` bg-${background}`;
  }

  if (background && !lightBackgrounds.includes(background)) {
    classNames += ' text-white';
  }

  if (wider) {
    classNames += ' md:w-2/3';
  }

  if (single) {
    classNames += ' container';
  }

  if (shifted) {
    classNames += ' relative z-10 -mt-24 xl:-mt-80';
  }

  const content = (
    <React.Fragment>
      {topline && <h4 className="text-xl font-normal">{topline}</h4>}
      {headline && (
        <h3
          className={topline ? '-mt-3' : ''}
          dangerouslySetInnerHTML={{ __html: headline }}
        ></h3>
      )}
      {children}
    </React.Fragment>
  );

  if (align) {
    return (
      <div className={classNames}>
        <div
          className={`children-margin ${
            wider ? 'max-w-706px' : 'max-w-576px'
          } ${align === 'right' && 'ml-auto'}`}
        >
          {content}
        </div>
      </div>
    );
  }

  return <div className={classNames}>{content}</div>;
};

ContentBox.propTypes = {
  topline: PropTypes.string,
  headline: PropTypes.string,
  background: PropTypes.string,
  wider: PropTypes.bool,
  single: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right']),
  shifted: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ContentBox;
