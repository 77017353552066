import React from 'react';
import PropTypes from 'prop-types';

const HalvedContent = ({ swapped, align, children, breakpoint, ...props }) => {
  let classNames = 'halved-content';

  if (breakpoint) {
    classNames += `-${breakpoint}`;
  }

  if (align === 'center') {
    classNames += ' container';
  }

  if (align && align !== 'center') {
    classNames += ` container container--${align}-aligned`;
  }

  if (swapped) {
    classNames += ' halved-content--swapped';
  }

  return (
    <div className={classNames} {...props}>
      {children.map((child, index) => (
        <React.Fragment key={index}>{child}</React.Fragment>
      ))}
    </div>
  );
};

HalvedContent.propTypes = {
  swapped: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
  breakpoint: PropTypes.oneOf(['xl'])
};

export default HalvedContent;
