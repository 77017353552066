import React from 'react';
import PropTypes from 'prop-types';

const Paragraphs = ({ innerHTML, items }) => {
  if (innerHTML) {
    return items.map((item, index) => (
      <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
    ));
  }

  return items.map((item, index) => <p key={index}>{item}</p>);
};

Paragraphs.propTypes = {
  innerHTML: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Paragraphs;
